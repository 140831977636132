import CIcon from "@coreui/icons-react";
import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from "@coreui/react";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { logoNegative } from '../../assets/brand/logo-negative';
import { sygnet } from '../../assets/brand/sygnet';
import SimpleBar from "simplebar-react";
import { AppSidebarNav } from './AppSidebarNav';
import navigation from './_nav';

const AppSidebar = () => {
    const dispatch = useDispatch()
    const unfoldable = useSelector((state) => state.sidebarUnfoldable)
    const sidebarShow = useSelector((state) => state.sidebarShow)

    return(
        <CSidebar position="fixed" unfoldable={unfoldable} visible={sidebarShow} onVisibleChange={(visible) => {
            dispatch({ type: 'set', sidebarShow: visible})
        }}>
            <CSidebarBrand className="d-none d-md-flex" to="/">
                <CIcon className="sidebar-brand-full" icon={logoNegative} height={35} />
                <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />
            </CSidebarBrand>
            <CSidebarNav>
                <SimpleBar>
                    <AppSidebarNav items={navigation} />
                </SimpleBar>
            </CSidebarNav>
            <CSidebarToggler 
            className="d-none d-lg-flex"  
            onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
            />
        </CSidebar>
    )

}

export default React.memo(AppSidebar)