import React from "react";
import Facebook from  "../../assets/img/sosial media/Facebook.svg";
import Twitter from "../../assets/img/sosial media/Twitter.svg";
import Instagram from "../../assets/img/sosial media/Instagram.svg";
import IconSmayani from "../../assets/img/Logo.svg";

export default function Footer(){
    return(
        <footer className='bg-gray-100 py-20'>
        <div className='container mx-auto max-w-6xl flex flex-row space-x-24'>
          <div className='flex-1 space-y-5'>
            <img src={IconSmayani} alt="logo smayani" className='w-36' />
            <div>
              Smayani Batang adalah sebuah sekolah islami modern yang berada di kabupaten batang
              provinsi jawa tengah
            </div>
            <div className='flex flex-row'>
              <img src={Facebook} alt="Facebook icon" className='w-16 h-16' />
              <img src={Twitter} alt="twitter icon" className='w-16 h-16' />
              <img src={Instagram} alt="Instagram icon" className='w-16 h-16' />
            </div>
            <div>@2023FanHerya</div>
          </div>
        </div>
      </footer>
    )
}