import './App.css';
import admin from './components/adminpage/AppSidebar';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
import Registration from "./components/registration/Registration";
// import AdminPage from "./components/adminpage/Admin";
import { BrowserRouter as Router, Routes, Route, HashRouter } from 'react-router-dom';
import Main from './components/main/Main';
// import {BrowserRouter as Route, Router, Routes} from 'react-router-dom';
import React, { Component, Suspense } from 'react';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// pages
const Beranda = React.lazy(() => import('./components/main/Main'))

class App extends Component {
  render() {
    return(
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/home" name="Beranda" element={<Beranda />} />
            <Route exact path="*" name="Home" element={<Main />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

// function App() {
 
//   return (
//     <HashRouter>
//       <Suspense fallback={loading}
//     </HashRouter>
//       <Router>
//         <Routes>
//           {/* <Route path='/' element={<Main />} /> */}
//           <Route path='/registration' element={<Registration />} />    
//           <Route path="*" name="Home" element={<DefaultLayout />} />
//         </Routes>
//       </Router>

      
      
//   );
// }

export default App;
