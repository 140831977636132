import React from "react";
import Illustration1 from "../../assets/img/Illustration_1.png";
import IconUsers from "../../assets/img/users.png";
import IconGreen from "../../assets/img/green.svg";
import IconServer from "../../assets/img/Server.svg";
import Illustration2 from "../../assets/img/Illustration 2.svg";
import Check from "../../assets/img/Check.svg";
import Review from '../../components/Review';
import SubscribeNow from '../../components/SubscribeNow';
import Header from "../header/Header";
import Foorter from "../footer/Footer";


const Main = () => {
  const section1 = [
            {
              icon: IconUsers,
              lable: "Siswa",
              total: "100+",
            },
            {
              icon: IconGreen,
              lable: "Green Area",
              total: "1+",
            },
            {
              icon: IconServer,
              lable: "Domain Server",
              total: "2+"
            }
          ];
    
          const features = [
            "Software Engineer in IT Industri",
            "Profesional Team Marching Band",
            "Good mentality with dzikir community",
            "Enhancement Attitude to be a good people"
          ]
    
  return(
    <div className="bg-white">
            <Header />
            <main>
        <div className='container max-w-6xl mx-auto grid grid-cols-2 py-24'>
          <div>
            <h1 className='font-bold text-4xl pb-5'>
              SMAYANI BATANG
              <br />
              Islamic Senior High Scholl
            </h1>
            <div className='font-normal text-xs pb-12'>
              Sekolah menengah atas dengan balutan ilmu islami dan dzikir yang bisa mengantarkan 
              masa depan dengan gemilang, dengan pondasi agama yang kuat
            </div>
            <button className='py-4 px-16 bg-red-500 rounded-md text-while drop-shadow-3xl'>
              Get Started
            </button>
          </div>
          <div>
            <img src={Illustration1} alt='ilustration1'/>
          </div>
        </div>
        <div className='container max-w-6xl mx-auto grid grid-cols-3 shadow-2xl rounded-md py-4'>
            {section1.map((val, index) => {
              return (
                <div
                  key={index}
                  className={`flex flex-row py-4 space-x-6 justify-center ${
                    index + 1 !== section1.length && "border-r border-gray-200"
                  }`}>
                    <div className='rounded-full bg-red-100 p-3'>
                      <img alt={val.lable} src={val.icon} className="w-6 h-6" />
                    </div>
                    <div>
                      <div>{val.total}</div>
                      <div>{val.lable}</div>
                    </div>
                  </div>
              );
            })}
        </div>
        {/* section 2 */}
        <div className='container max-w-6xl mx-auto grid grid-cols-2 py-24 items-center'>
          <img src={Illustration2} alt={"feature-smayani"}/>
          <div className='px-16 space-y-4'>
            <div className='font-medium text-3xl'>
              Banyak Kelebihan Dari SMAYANI Batang
            </div>
            <div className='text-sm font-normal'>
              Kamu dapat mendapatkan pengetahuan lebih serta bisa explore lebih banyak lagi seperti :
            </div>
            {features.map((val, index) => {
              return (
                <div className='flex items-center space-x-3' key={index}>
                  <img src={Check} alt="Feature-check-list" className='w-6 h-6'/>
                  <div className='text-xs'>{val}</div>
                </div>
              );
            })}
          </div>
        </div>
        {/* Section 3 dari Component */}
        <div className='container max-w-6xl mx-auto py-14'>
            <Review />
        </div>
        {/* section 4  */}
        <div className='bg-gray-100'>
          <SubscribeNow />
        </div>
      </main>
      <Foorter />
        </div>
  )
}

export default Main

// export default function Main(){

//     const section1 = [
//         {
//           icon: IconUsers,
//           lable: "Siswa",
//           total: "100+",
//         },
//         {
//           icon: IconGreen,
//           lable: "Green Area",
//           total: "1+",
//         },
//         {
//           icon: IconServer,
//           lable: "Domain Server",
//           total: "2+"
//         }
//       ];

//       const features = [
//         "Software Engineer in IT Industri",
//         "Profesional Team Marching Band",
//         "Good mentality with dzikir community",
//         "Enhancement Attitude to be a good people"
//       ]

//     return(
//         <div className="bg-white">
//             <Header />
//             <main>
//         <div className='container max-w-6xl mx-auto grid grid-cols-2 py-24'>
//           <div>
//             <h1 className='font-bold text-4xl pb-5'>
//               SMAYANI BATANG
//               <br />
//               Islamic Senior High Scholl
//             </h1>
//             <div className='font-normal text-xs pb-12'>
//               Sekolah menengah atas dengan balutan ilmu islami dan dzikir yang bisa mengantarkan 
//               masa depan dengan gemilang, dengan pondasi agama yang kuat
//             </div>
//             <button className='py-4 px-16 bg-red-500 rounded-md text-while drop-shadow-3xl'>
//               Get Started
//             </button>
//           </div>
//           <div>
//             <img src={Illustration1} alt='ilustration1'/>
//           </div>
//         </div>
//         <div className='container max-w-6xl mx-auto grid grid-cols-3 shadow-2xl rounded-md py-4'>
//             {section1.map((val, index) => {
//               return (
//                 <div
//                   key={index}
//                   className={`flex flex-row py-4 space-x-6 justify-center ${
//                     index + 1 !== section1.length && "border-r border-gray-200"
//                   }`}>
//                     <div className='rounded-full bg-red-100 p-3'>
//                       <img alt={val.lable} src={val.icon} className="w-6 h-6" />
//                     </div>
//                     <div>
//                       <div>{val.total}</div>
//                       <div>{val.lable}</div>
//                     </div>
//                   </div>
//               );
//             })}
//         </div>
//         {/* section 2 */}
//         <div className='container max-w-6xl mx-auto grid grid-cols-2 py-24 items-center'>
//           <img src={Illustration2} alt={"feature-smayani"}/>
//           <div className='px-16 space-y-4'>
//             <div className='font-medium text-3xl'>
//               Banyak Kelebihan Dari SMAYANI Batang
//             </div>
//             <div className='text-sm font-normal'>
//               Kamu dapat mendapatkan pengetahuan lebih serta bisa explore lebih banyak lagi seperti :
//             </div>
//             {features.map((val, index) => {
//               return (
//                 <div className='flex items-center space-x-3' key={index}>
//                   <img src={Check} alt="Feature-check-list" className='w-6 h-6'/>
//                   <div className='text-xs'>{val}</div>
//                 </div>
//               );
//             })}
//           </div>
//         </div>
//         {/* Section 3 dari Component */}
//         <div className='container max-w-6xl mx-auto py-14'>
//             <Review />
//         </div>
//         {/* section 4  */}
//         <div className='bg-gray-100'>
//           <SubscribeNow />
//         </div>
//       </main>
//       <Foorter />
//         </div>
        
//     )
// }