import React  from "react";
import IconSmayani from "../../assets/img/Logo.svg";


const Header = () => {
    // const [click, setClick] = useState(false)

    const menus = [
      {id: 1, name: 'Beranda', href: '/', current: true},
      {id: 2, name: 'Profile', href: 'Profile', current: true},
      {id: 3, name: 'Ekstrakurikuler', href: 'Ekstrkurikuler', current: true},
      {id: 4, name: 'Registration', href: 'Registration', current: true},
      {id: 5, name: 'Contact Person', href: 'Contact', current: true}
    ]
    return (
        <header className='container max-w-6xl mx-auto flex flex-row pt-12 items-center space-x-28'>
        <img alt='icon-smayani' src={IconSmayani} className="w-36" />
        <div className='flex-1'>
          <ul className='flex flex-row space-x-6'>
            {menus.map((item) =>(
              <a key={item.id} name={item.name} href={item.href}>
                {item.name}
              </a>
            ))}
          </ul>
        </div>
        <div className='space-x-6 flex flex-row  items-center'>
          <button className='font-bold'>Masuk</button>
          <button className='border border-red-500 rounded-full py-2 px-6'>
            Pendaftaran
          </button>
        </div>
        </header>
    )
}

export default Header
